.totaleOreManodopera {
  background-image: url('/assets/worker.png');
  background-repeat: no-repeat;
  display: block;
}

.totaleOreMezzi {
  background-image: url('/assets/tools.png');
  background-repeat: no-repeat;
  display: block;
}

.group-data {
  background-color: #ed9121;
}

.dx-datagrid .dx-row > td {
   font-size: 16px;
}
